import { GridRenderCellParams } from "@mui/x-data-grid";
import React from "react";
import { IStravaActivity } from "../../Models/IStravaActivity";
import { useNavigate } from "react-router-dom";
import { IClubEvent } from "../../Models/IEventRunners";
import { Button } from "@mui/material";

export const ActivityToEventNavigator = (
  params: GridRenderCellParams<any, IStravaActivity, any>
) => {
  if (params.row) {
    const name = params.row.name;
    const club = params.row.club;

    return <EventNavigator club={club} name={name}></EventNavigator>;
  }
  return <div></div>;
};

export interface IEventNavigatorProps {
  club: string;
  name: string;
}

export const EventNavigator: React.FC<IEventNavigatorProps> = (props) => {
  const navigate = useNavigate();

  const viewUserProfile = React.useCallback(
    (eventKey: IClubEvent) => () => {
      navigate(`/event/${encodeURIComponent(eventKey.clubEvent)}`);
    },
    [navigate]
  );

  const eventKey: IClubEvent = {
    clubEvent: `${props.name}#${props.club}`,
  };

  return (
    <strong>
      <Button variant="text" onClick={viewUserProfile(eventKey)}>
        {props.name}
      </Button>
    </strong>
  );
};
