import React from "react";
import { IRunner } from "../../Models/IRunner";
import { Dialog, Paper } from "@mui/material";
import { IRunnersDialogProps } from "./IRunnersDialogProps";

import { IEvent } from "../../Models/IEvent";
import { ConfigHelper } from "../../Config/ConfigHelper";
import axios from "axios";
import { RunnersDialogContent } from "./RunnersDialogContent";
import { datePopulated } from "../Helpers/DatePopulated";
import { Loading } from "../Feedback/Loading";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../Contexts/ClubContext";

export function RunnersDialog(props: IRunnersDialogProps) {
  const navigate = useNavigate();
  const { selectedClub } = useGlobalContext();

  const { onClose, open } = props;

  const handleClose = () => {
    onClose(undefined);
  };

  const handleEventUpdate = (value: IEvent) => {
    onClose(value);
  };

  const handleListItemClick = (value: IRunner) => {
    navigate(
      `/racer/${encodeURIComponent(selectedClub)}/${encodeURIComponent(
        value.userId
      )}`
    );

    //Used to close the window but we probably want to drill down
    //onClose(value);
  };

  const [event, setEvent] = React.useState<IEvent>();

  React.useEffect(() => {
    if (props.eventKey !== undefined && datePopulated(props.eventKey.dateId)) {
      const url = new ConfigHelper().GetEventUrl(props.eventKey);

      const fetchData = async () => {
        const response = await axios.get<IEvent>(url);

        setEvent(response.data);
      };

      fetchData();
    }
  }, [props.eventKey]);

  if (event === undefined && !open) {
    return <></>;
  }

  if (
    props.eventKey === undefined ||
    datePopulated(props.eventKey.dateId) === false
  ) {
    return <></>;
  }

  if (event === undefined) {
    const message = `Loading... ${props.eventKey.clubEvent} on ${props.eventKey.dateId}`;
    return (
      <Paper elevation={3}>
        <Loading loadingMessage={message} />
      </Paper>
    );
  }

  const content = RunnersDialogContent(
    {
      confirmedRunners: event.confirmedRunnersDetailed,
      interestedRunners: event.interestedRunnersDetailed,
      withdrawnRunners: event.withdrawnRunnersDetailed,
      racers: event.racers,

      ...props,
    },
    handleListItemClick,
    handleEventUpdate
  );

  if (props.showInDialog) {
    return (
      <Dialog onClose={handleClose} open={open}>
        {content}
      </Dialog>
    );
  }

  return <Paper elevation={3}>{content};</Paper>;
}
