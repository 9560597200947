import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { PageLayout } from "../PageOrganiser/PageLayout";
import { CodeSnippet } from "../CodeSnippet/code-snippet";
import {
  BrowserView,
  MobileOnlyView,
  browserName,
  osName,
  osVersion,
  isBrowser,
  isDesktop,
  isMobile,
  isTablet,
} from "react-device-detect";
import { List, ListItem } from "@mui/material";
import { toMyString } from "../Helpers/ToValue";
import { ConnectToStravaButton } from "../Strava/ConnectToStravaButton";

export const ProfilePage: React.FC = () => {
  const { user } = useAuth0();

  if (!user) {
    return null;
  }
  const userSplit = toMyString(user.sub, "").split("|");
  const userProvider = userSplit[0];
  const userId = userSplit[1];

  // const redirectUrl = `${new ConfigHelper().GetStravaRedirectUrl()}/redirect/${userProvider}/${userId}`;
  // const scope = "read";

  // const handleLogin = () => {
  //   //levelup.gitconnected.com/add-strava-oauth2-login-to-your-react-app-in-15-minutes-6c92e845919e
  //   const url = `http://www.strava.com/oauth/authorize?client_id=2149&response_type=code&redirect_uri=${redirectUrl}/exchange_token&approval_prompt=force&scope=${scope}`;
  //   window.location.href = url;
  // };

  return (
    <PageLayout>
      <div className="content-layout">
        <h1 id="page-title" className="content__title">
          Profile Page
        </h1>
        <div className="content__body">
          <p id="page-description">
            <span>
              You can use the <strong>ID Token</strong> to get the profile
              information of an authenticated user.
            </span>
            <span>
              <strong>Only authenticated users can access this page.</strong>
            </span>
          </p>
          <div className="profile-grid">
            <div className="profile__header">
              <img
                src={user.picture}
                alt="Profile"
                className="profile__avatar"
              />
              <div className="profile__headline">
                <h2 className="profile__title">{user.name}</h2>
                <span className="profile__description">{user.email}</span>
                <List>
                  <ListItem> userProvider {userProvider}</ListItem>
                  <ListItem> userId {userId}</ListItem>
                </List>
              </div>
            </div>
            <div>
              <ConnectToStravaButton userId={toMyString(user.sub)} />
            </div>
            <div className="profile__details">
              <CodeSnippet
                title="Decoded ID Token"
                code={JSON.stringify(user, null, 2)}
              />
            </div>
          </div>
          <div className="profile__details">
            <h2 className="profile__title">Your device</h2>
            <BrowserView>
              <span className="profile__description">Browser View</span>
            </BrowserView>
            <MobileOnlyView>
              <span className="profile__description">Mobile View</span>
            </MobileOnlyView>

            <List>
              <ListItem> browserName is {browserName}</ListItem>
              <ListItem> osName is {osName}</ListItem>
              <ListItem> osVersion is {osVersion}</ListItem>
              <ListItem> isBrowser is {isBrowser.toString()}</ListItem>
              <ListItem> isDesktop is {isDesktop.toString()}</ListItem>
              <ListItem> isMobile is {isMobile.toString()}</ListItem>
              <ListItem> isTablet is {isTablet.toString()}</ListItem>
            </List>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};
