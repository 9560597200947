import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React from "react";
import { ConfigHelper } from "../../Config/ConfigHelper";
import { useGlobalContext } from "../../Contexts/ClubContext";
import { toMyString } from "../Helpers/ToValue";
import { NavBarTab } from "../Navigation/Desktop/nav-bar-tab";
import { ConnectToStravaButton } from "./ConnectToStravaButton";

export interface IViewStravaConnectOrPages {
  supressConnectedAlreadyCheck: boolean;
}

export const ViewStravaConnectOrPages: React.FC<IViewStravaConnectOrPages> = (
  props
) => {
  const { user } = useAuth0();
  const [conectedToStrava, setConectedToStrava] = React.useState<boolean>();
  const { selectedClub } = useGlobalContext();

  React.useEffect(() => {
    if (!user || props.supressConnectedAlreadyCheck) {
      setConectedToStrava(false);
      return;
    }

    const url = new ConfigHelper().GetIsUserConnectedToStravaUrl(
      selectedClub,
      toMyString(user.sub)
    );

    const fetchData = async () => {
      try {
        const response = await axios.get<boolean>(url);

        if (response.status === 200) {
          setConectedToStrava(response.data);
        } else {
          console.log(response.statusText);
        }
      } catch (err) {
        console.error("Strava check failed:", err);
      }
    };

    fetchData();
  }, [user, selectedClub, props.supressConnectedAlreadyCheck]);

  if (!user) {
    return null;
  }

  if (conectedToStrava === false) {
    return <ConnectToStravaButton userId={toMyString(user.sub)} />;
  }

  return (
    <div className="nav-bar__tabs">
      <NavBarTab path="/StravaRaces" label="My Races" />
    </div>
  );
};
