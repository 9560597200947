import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import axios from "axios";
import React from "react";

import { ConfigHelper } from "../../Config/ConfigHelper";
import { useGlobalContext } from "../../Contexts/ClubContext";
import { IClubEvent } from "../../Models/IEventRunners";
import { ILeaderBoard } from "../../Models/ILeaderBoard";
import { IStravaActivity } from "../../Models/IStravaActivity";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Loading } from "../Feedback/Loading";

import { RaceGrid, RaceGridColumn } from "./RaceGrid";

export interface IRacesByName extends IClubEvent {}

export const getRaceName = (props: IClubEvent): string => {
  return props.clubEvent.lastIndexOf("#") > 0
    ? props.clubEvent.split("#")[0]
    : `No Name In club Event ${props.clubEvent}`;
};

export const RacesByName: React.FC<IRacesByName> = (props) => {
  const { selectedClub } = useGlobalContext();

  const raceName: string = getRaceName(props);

  const raceLabel = `${raceName} for club ${selectedClub}`;
  const [races, setRaces] = React.useState<IStravaActivity[]>();

  const [apiError, setApiError] = React.useState<string>("");

  React.useEffect(() => {
    const url = new ConfigHelper().GetStravaRacesByNameUrl(
      selectedClub,
      raceName
    );

    const fetchData = async () => {
      const response = await axios.get<ILeaderBoard[]>(url);

      if (response.status === 200) {
        const league = response.data;
        const races: IStravaActivity[] = [];

        for (let iLeague = 0; iLeague < league.length; iLeague++) {
          const leagueRaces = league[iLeague].races;
          for (let iRace = 0; iRace < leagueRaces.length; iRace++) {
            const race = leagueRaces[iRace];
            race.runner = league[iLeague].runner;
            races.push(race);
          }
        }
        races.sort(function (a, b) {
          return a.paceKmSeconds - b.paceKmSeconds;
        });
        let clubPos = 1;
        races.forEach((x) => {
          x.clubPosition = clubPos;
          clubPos++;
        });

        setRaces(races);
      } else {
        setApiError(response.statusText);
      }
    };

    fetchData();
  }, [selectedClub, raceName]);

  if (races) {
    if (races.length === 0) {
      return <div>No Past Racers for {raceLabel}</div>;
    } else {
      return (
        <Accordion expanded={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            Past Racers {raceLabel}
          </AccordionSummary>
          <AccordionDetails>
            <RaceGrid
              raceGridColumn={RaceGridColumn.ScrapedForRunner}
              races={races}
            ></RaceGrid>
          </AccordionDetails>
        </Accordion>
      );
    }
  }

  if (apiError.length > 1) {
    return <div>Error Occured - {apiError}</div>;
  }

  return <Loading loadingMessage={`Loading ${raceLabel}`} />;
};
