import React from "react";
import { NavBarTab } from "./nav-bar-tab";

export const NavBarTabs: React.FC = () => {
  return (
    <div className="nav-bar__tabs">
      <NavBarTab path="/profile" label="Profile" />
      <NavBarTab path="/" label="Events" />
      <NavBarTab path="/topracers" label="Top Racers" />
      <NavBarTab path="/chatbot" label="Chat Bot" />
      {/* <NavBarTab path="/historic" label="Historic" /> */}
    </div>
  );
};
