import React, { useState, useRef, useEffect } from "react";
import { ReactNode } from "react";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import {
  Box,
  TextField,
  Collapse,
  IconButton,
  Paper,
  Typography,
  CircularProgress,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { oneLight } from "react-syntax-highlighter/dist/esm/styles/prism";

import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

interface MatchResult {
  match: string;
  method: string;
  trigger: string;
  is_found: boolean;
}

interface QuestionTypeResponse {
  types: string[];
  runner_matches: MatchResult[];
  club_matches: MatchResult[];
  race_matches: MatchResult[];
}

interface ChatMessage {
  question: string;
  response?: {
    code: string;
    result: string | Record<string, any>[];
    result_type: "text" | "dataframe" | "error";
    question_type: QuestionTypeResponse;
    runner_summary: string;
    result_summary: string;
  };
}

const Chat: React.FC = () => {
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const [showTable, setShowTable] = useState(false);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleSend = async () => {
    if (!input.trim()) return;
    const newMessage: ChatMessage = { question: input };
    setMessages((prev) => [...prev, newMessage]);
    setInput("");
    setLoading(true);

    try {
      const baseUrl =
        process.env.REACT_APP_CHAT_API_URL ||
        "https://race-bot-service-uk.azurewebsites.net";

      console.log(`Asking ${baseUrl}`);
      const res = await fetch(`${baseUrl}/ask`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ question: input }),
      });

      const data = await res.json();
      setMessages((prev) => {
        const updated = [...prev];
        updated[updated.length - 1].response = data;
        return updated;
      });
    } catch (err) {
      console.error("Failed to fetch response", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ mx: "auto", textAlign: "left", mb: 2 }}>
      <Box sx={{ flexGrow: 1, overflowY: "auto", p: 2 }}>
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          components={{
            h1: ({ children }) => (
              <Typography
                component="h1"
                variant="h4"
                fontWeight="bold"
                gutterBottom
              >
                {children}
              </Typography>
            ),
          }}
        >
          {`# West Sussex Fun Run League Chat Bot

Here are some things you can ask:
- Can you compare position and points year on year between **Marcus Kimmins** and **Andy Hind**?
- Can you give me a detailed break down of **Phil Scott's** runs?
- Can you group all the **Haywards Heath Harrier** by years run and show year, amount of runners (unique and total) and the fastest/slowest paces?
- What was **Barry Tullet's** last race and in which year? 
`}
        </ReactMarkdown>

        <Box>
          {messages.map((msg, index) => (
            <Paper key={index} sx={{ p: 2 }} elevation={3}>
              {
                <BannerText
                  label="You asked:"
                  text={msg.question}
                  icon={<ChatBubbleOutlineIcon />}
                />
              }

              {msg.response ? (
                <>
                  {msg.response.result_type === "dataframe" &&
                  Array.isArray(msg.response.result) ? (
                    <Box
                      sx={{
                        mt: 1,
                        backgroundColor: "#ffffff",
                        borderRadius: 1,
                        boxShadow: 1,
                        p: 2,
                        width: "100%",
                        "& *": {
                          textAlign: "left !important",
                        },
                        "& ul, & ol": {
                          pl: 2,
                          listStyle: "disc inside",
                          m: 0,
                        },
                        "& li": {
                          mb: 1,
                          pl: 1,
                        },
                        "& p": {
                          mb: 1,
                        },
                        "& h1, & h2, & h3, & h4, & h5, & h6": {
                          fontWeight: "bold",
                          mt: 2,
                          mb: 1,
                          color: "#000", // 🖤 Make all headings black
                        },
                        "& strong": {
                          fontWeight: "bold",
                        },
                      }}
                    >
                      <ReactMarkdown
                        remarkPlugins={[remarkGfm]}
                        components={{
                          ul: ({ node, ...props }) => (
                            <ul style={{ paddingLeft: 20 }} {...props} />
                          ),
                          ol: ({ node, ...props }) => (
                            <ol style={{ paddingLeft: 20 }} {...props} />
                          ),
                        }}
                      >
                        {msg.response.result_summary}
                      </ReactMarkdown>
                      {/* Expand / Collapse toggle */}
                      <Box
                        sx={{ display: "flex", alignItems: "center", mt: 1 }}
                      >
                        <BannerText
                          label="Data That Drove The Answer:"
                          text={`${msg.response.result.length} results`}
                          color={
                            msg.response.result.length > 0
                              ? "#388e3c"
                              : "#fbc02d"
                          }
                          backgroundColor={
                            msg.response.result.length > 0
                              ? "#e8f5e9"
                              : "#fffde7"
                          }
                          icon={
                            msg.response.result.length > 0 ? (
                              <CheckCircleOutlineIcon />
                            ) : (
                              <ChatBubbleOutlineIcon />
                            )
                          }
                        />
                        <IconButton
                          size="small"
                          onClick={() => setShowTable((prev) => !prev)}
                        >
                          {showTable ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                      </Box>

                      <Collapse in={showTable}>
                        <Box sx={{ mt: 1, maxHeight: 400, overflow: "auto" }}>
                          {msg.response.result.length > 0 ? (
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  {Object.keys(msg.response.result[0]).map(
                                    (key) => (
                                      <TableCell key={key}>{key}</TableCell>
                                    )
                                  )}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {msg.response.result.map((row, i) => (
                                  <TableRow key={i}>
                                    {Object.values(row).map((val, j) => (
                                      <TableCell key={j}>
                                        {String(val)}
                                      </TableCell>
                                    ))}
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          ) : (
                            <Box
                              sx={{
                                backgroundColor: "#fffde7",
                                borderLeft: "4px solid #fbc02d",
                                p: 2,
                                borderRadius: 1,
                                mt: 1,
                                fontStyle: "italic",
                                color: "#666",
                              }}
                            >
                              No results returned for your question. Please try
                              asking something else.
                            </Box>
                          )}
                        </Box>
                      </Collapse>
                    </Box>
                  ) : (
                    <BannerText
                      label="Answer:"
                      text={msg.response.result.toString()}
                      color="#388e3c"
                      backgroundColor="#e8f5e9"
                      icon={<CheckCircleOutlineIcon />}
                    />
                  )}

                  {/* Runner Summary */}
                  {msg.response.runner_summary?.trim() && (
                    <Box sx={{ mt: 2 }}>
                      <BannerText
                        label="Runners Found in Question:"
                        text={msg.response.question_type.runner_matches
                          .filter((r) => r.is_found)
                          .map((r) => r.match)
                          .join(", ")}
                        color="#616161"
                        backgroundColor="#f5f5f5"
                        icon={<DirectionsRunIcon />}
                      />
                    </Box>
                  )}
                  {Array.isArray(msg.response.question_type.runner_matches) &&
                    msg.response.question_type.runner_matches
                      .filter((r) => r.is_found)
                      .map((runner, index) => {
                        const rawSummary = msg.response?.runner_summary || "";
                        const summaries = rawSummary
                          .split("\n\n")
                          .filter(Boolean);
                        const summaryText =
                          summaries[index] ||
                          "No summary available for this runner.";

                        return (
                          <Box key={runner.match} sx={{ mt: 2 }}>
                            <Paper
                              variant="outlined"
                              sx={{
                                p: 2,
                                backgroundColor: "#fafafa",
                                whiteSpace: "pre-line",
                                borderLeft: "4px solid #616161",
                              }}
                            >
                              {summaryText}
                            </Paper>
                          </Box>
                        );
                      })}

                  <Accordion sx={{ mt: 2 }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography variant="subtitle1" fontWeight="bold">
                        How did we generate this answer?
                      </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                      <Box
                        sx={{ maxWidth: 600, mx: "auto", textAlign: "left" }}
                      >
                        {/* Question Types */}
                        <Box sx={{ mb: 2 }}>
                          <Typography
                            variant="body2"
                            fontWeight="bold"
                            gutterBottom
                          >
                            The question classifier found these types:
                          </Typography>
                          <Typography variant="body2">
                            {msg.response.question_type.types.join(", ")}
                          </Typography>
                        </Box>

                        {/* Runner Matches */}
                        {msg.response.question_type.runner_matches.length >
                          0 && (
                          <Box sx={{ mb: 2 }}>
                            <Typography
                              variant="body2"
                              fontWeight="bold"
                              gutterBottom
                            >
                              Runner Matches:
                            </Typography>
                            {msg.response.question_type.runner_matches.map(
                              (match, idx) => (
                                <Typography key={idx} variant="body2">
                                  {match.match} (method: <em>{match.method}</em>
                                  , trigger: "<em>{match.trigger}</em>")
                                </Typography>
                              )
                            )}
                          </Box>
                        )}

                        {/* Club Matches */}
                        {msg.response.question_type.club_matches.length > 0 && (
                          <Box sx={{ mb: 2 }}>
                            <Typography
                              variant="body2"
                              fontWeight="bold"
                              gutterBottom
                            >
                              Club Matches:
                            </Typography>
                            {msg.response.question_type.club_matches.map(
                              (match, idx) => (
                                <Typography key={idx} variant="body2">
                                  {match.match} (method: <em>{match.method}</em>
                                  , trigger: "<em>{match.trigger}</em>")
                                </Typography>
                              )
                            )}
                          </Box>
                        )}

                        {/* Race Matches */}
                        {msg.response.question_type.race_matches.length > 0 && (
                          <Box sx={{ mb: 2 }}>
                            <Typography
                              variant="body2"
                              fontWeight="bold"
                              gutterBottom
                            >
                              Race Matches:
                            </Typography>
                            {msg.response.question_type.race_matches.map(
                              (match, idx) => (
                                <Typography key={idx} variant="body2">
                                  {match.match} (method: <em>{match.method}</em>
                                  , trigger: "<em>{match.trigger}</em>")
                                </Typography>
                              )
                            )}
                          </Box>
                        )}

                        {/* Python Code */}
                        <Typography
                          variant="body2"
                          fontWeight="bold"
                          gutterBottom
                        >
                          Python Code Used:
                        </Typography>
                        <SyntaxHighlighter
                          language="python"
                          style={oneLight}
                          showLineNumbers
                          wrapLines
                          customStyle={{
                            borderRadius: "8px",
                            marginTop: "8px",
                          }}
                        >
                          {msg.response.code}
                        </SyntaxHighlighter>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </>
              ) : loading && index === messages.length - 1 ? (
                <CircularProgress size={20} sx={{ mt: 1 }} />
              ) : null}
            </Paper>
          ))}
          <div ref={messagesEndRef} />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          p: 2,
          borderTop: "1px solid #ddd",
          backgroundColor: "white",
        }}
      >
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Ask a question..."
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && handleSend()}
        />
        <IconButton color="primary" onClick={handleSend} disabled={loading}>
          <SendIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Chat;

interface BannerTextProps {
  label: string;
  text: string | ReactNode;
  color?: string;
  backgroundColor?: string;
  icon?: ReactNode;
}
function isMarkdown(text: string): boolean {
  // Basic check for markdown syntax
  return /[*_`#\-~]|(\n\s*\d+\.)/.test(text);
}
function BannerText({
  label,
  text,
  color = "#1976d2",
  backgroundColor = "#f0f4ff",
  icon,
}: BannerTextProps) {
  const renderContent = () => {
    if (typeof text === "string" && isMarkdown(text)) {
      return (
        <Box
          sx={{
            mt: 1,
            backgroundColor: "#ffffff",
            borderRadius: 1,
            boxShadow: 1,
            p: 2,
            textAlign: "left",
            width: "100%",
            "& *": {
              textAlign: "left !important",
            },
            "& ul, & ol": {
              pl: 2,
              listStyle: "disc inside", // ✅ Ensures proper bullet alignment
              m: 0,
            },
            "& li": {
              mb: 1,
              pl: 1, // indent spacing
            },
            "& p": {
              mb: 1,
            },
            "& h1, & h2, & h3": {
              fontWeight: "bold",
              mt: 2,
              mb: 1,
            },
            "& strong": {
              fontWeight: "bold",
            },
          }}
        >
          <ReactMarkdown remarkPlugins={[remarkGfm]}>{text}</ReactMarkdown>
        </Box>
      );
    }

    if (typeof text === "string") {
      return (
        <Typography
          component="span"
          fontStyle="italic"
          sx={{ color: "text.primary", textTransform: "capitalize" }}
        >
          “{text}”
        </Typography>
      );
    }

    return text;
  };

  return (
    <Box
      sx={{
        backgroundColor,
        borderLeft: `4px solid ${color}`,
        p: 2,
        borderRadius: 1,
        mb: 2,
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
        {icon && (
          <Box sx={{ mr: 1, color, display: "flex", alignItems: "center" }}>
            {icon}
          </Box>
        )}
        <Typography
          component="span"
          variant="subtitle1"
          fontWeight="bold"
          sx={{ color }}
        >
          {label}
        </Typography>
      </Box>

      {renderContent()}
    </Box>
  );
}
