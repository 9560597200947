import React from "react";
import { Button } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";

import { useNavigate } from "react-router-dom";
import { IRunnerCore } from "../../Models/IStravaActivity";

export const RacerNavigatorRow = (
  params: GridRenderCellParams<any, IRunnerCore, any>
) => {
  if (params.row) {
    const runner = params.row.runner;
    const club = params.row.club;

    return <RacerNavigator club={club} runner={runner}></RacerNavigator>;
  }
  return <div></div>;
};

export const RacerNavigator: React.FC<IRunnerCore> = (props) => {
  const navigate = useNavigate();

  const viewUserProfile = React.useCallback(
    (userKey: IRunnerCore) => () => {
      navigate(
        `/racer/${encodeURIComponent(userKey.club)}/${encodeURIComponent(
          userKey.runner.userId
        )}`
      );
    },
    [navigate]
  );

  return (
    <strong>
      <Button variant="text" onClick={viewUserProfile(props)}>
        {props.runner.fullName}
      </Button>
    </strong>
  );
};
