import React from "react";
import axios from "axios";

import { isMobile } from "react-device-detect";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import {
  DataGrid,
  GridRowsProp,
  GridColumns,
  GridValueFormatterParams,
  GridRenderCellParams,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import { ConfigHelper } from "../../Config/ConfigHelper";
import { Loading } from "../Feedback/Loading";
import { ILeaderBoard } from "../../Models/ILeaderBoard";
import { ILeagueByClub } from "./ILeagueByClub";
import { RaceHistoryDialog } from "../Races/RaceHistoryDialog";
import { IStravaActivity } from "../../Models/IStravaActivity";
import { Button } from "@mui/material";
import { RacerNavigatorRow } from "../Racer/RacerNavigator";

export const LeagueByClub: React.FC<ILeagueByClub> = (props) => {
  const [openRaceHistoryDialog, setOpenRaceHistoryDialog] =
    React.useState(false);
  const [selectedRaces, setSelectedRaces] = React.useState<IStravaActivity[]>(
    []
  );

  const [leaderBoard, setleaderBoard] = React.useState<ILeaderBoard[]>([]);

  const [apiError, setApiError] = React.useState<string>("");

  React.useEffect(() => {
    const url = new ConfigHelper().GetTopRacersUrl(props.club, props.year);

    const fetchData = async () => {
      const response = await axios.get<ILeaderBoard[]>(url);

      if (response.status === 200) {
        setleaderBoard(response.data);
        setSelectedRaces(response.data[0].races);
      } else {
        setApiError(response.statusText);
      }
    };

    fetchData();
  }, [props.club, props.year]);

  const widthForNumbers = 120;

  const valueFormatting = (params: GridValueFormatterParams<number>) => {
    if (params.value == null) {
      return "";
    }

    const valueFormatted = Number(params.value).toFixed(0).toLocaleString();
    return `${valueFormatted}`;
  };

  const handleCloseOfViewRaces = (value?: IStravaActivity) => {
    setOpenRaceHistoryDialog(false);
  };

  const viewUserRaces = React.useCallback(
    (races: IStravaActivity[]) => () => {
      setSelectedRaces(races);
      setOpenRaceHistoryDialog(true);
    },
    []
  );

  const renderViewRacesButton = React.useCallback(
    (params: GridRenderCellParams<any, ILeaderBoard, any>) => {
      if (params.row) {
        const races = params.row.races;

        return (
          <strong>
            <Button variant="text" onClick={viewUserRaces(races)}>
              {isMobile ? races.length : "Races"}
            </Button>
          </strong>
        );
      }

      return <Button variant="text">0</Button>;
    },
    [viewUserRaces]
  );

  const browserColumns = React.useMemo<GridColumns<ILeaderBoard>>(
    () => [
      {
        field: "runner",
        headerName: "Athlete",
        width: 200,
        valueFormatter: (params) => params.value.fullName,
        renderCell: RacerNavigatorRow,
      },
      {
        field: "raceCount",
        headerName: "Total Races",
        width: widthForNumbers,
        align: "right",
        valueFormatter: valueFormatting,
      },
      {
        field: "xcRaces",
        headerName: "XC Races",
        width: widthForNumbers,
        align: "right",
        valueFormatter: valueFormatting,
      },
      {
        field: "wsfrlRaces",
        headerName: "Fun Run Races",
        width: widthForNumbers,
        align: "right",
        valueFormatter: valueFormatting,
      },
      {
        field: "sgpRaces",
        headerName: "SPG Races",
        width: widthForNumbers,
        align: "right",
        valueFormatter: valueFormatting,
      },
      {
        field: "bestPlace",
        headerName: "Top Place",
        width: widthForNumbers,
        align: "right",
        valueFormatter: valueFormatting,
      },
      {
        field: "quickestPaceKm",
        headerName: "Quickest Pace",
        width: 90,
        align: "right",
        valueFormatter: (params) => params.value,
      },

      {
        field: "racersCount",
        headerName: "Raced",
        width: widthForMobileNumbers,
        align: "right",
        renderCell: renderViewRacesButton,
      },
    ],
    [renderViewRacesButton]
  );

  const widthForMobileNumbers = 50;

  const mobileColumns = React.useMemo<GridColumns<ILeaderBoard>>(
    () => [
      {
        field: "runner",
        headerName: "Athlete",
        width: 130,
        valueFormatter: (params) => params.value.fullName,
      },
      {
        field: "racersCount",
        headerName: "Total",
        width: widthForMobileNumbers,
        align: "right",
        renderCell: renderViewRacesButton,
      },

      {
        field: "xcRaces",
        headerName: "XC",
        width: widthForMobileNumbers,
        align: "right",
        valueFormatter: valueFormatting,
      },
      {
        field: "wsfrlRaces",
        headerName: "Funrun",
        width: widthForMobileNumbers,
        align: "right",
        valueFormatter: valueFormatting,
      },
      {
        field: "sgpRaces",
        headerName: "SPG",
        width: widthForMobileNumbers,
        align: "right",
        valueFormatter: valueFormatting,
      },
      {
        field: "actions",
        type: "actions",
        headerName: "View",
        width: widthForMobileNumbers,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<DirectionsRunIcon />}
            label="Runs"
            onClick={viewUserRaces(params.row.races)}
          />,
        ],
      },
    ],
    [renderViewRacesButton, viewUserRaces]
  );

  if (leaderBoard) {
    if (leaderBoard.length === 0) {
      return (
        <div>
          <p id="page-description">
            <span>
              No leader board found <strong>yet</strong>.
            </span>
            <span>
              Parameters club {props.club} year {props.year}
            </span>
          </p>
        </div>
      );
    }

    const rows: GridRowsProp<ILeaderBoard> = leaderBoard;

    const cols: GridColumns<ILeaderBoard> = isMobile
      ? mobileColumns
      : browserColumns;

    const gridHeight = isMobile ? 400 : 1000;

    return (
      <div>
        <div style={{ height: gridHeight, width: "100%" }}>
          <DataGrid
            getRowId={(row) => row.runner.userId}
            rows={rows}
            columns={cols}
          />
          <RaceHistoryDialog
            races={selectedRaces}
            open={openRaceHistoryDialog}
            onClose={handleCloseOfViewRaces}
            showInDialog={true}
          />
        </div>
      </div>
    );
  }

  if (apiError.length > 1) {
    return <div>Error Occured - {apiError}</div>;
  }

  return (
    <Loading
      loadingMessage={`Loading Top Racers for  ${props.club} in ${props.year}`}
    />
  );
};
