import React from "react";
import axios from "axios";
import { IEvent } from "../../Models/IEvent";
import { IEventKey } from "../../Models/IEventRunners";
import { IEventsProps } from "./IEventsProps";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import { isMobile } from "react-device-detect";

import {
  DataGrid,
  GridRowsProp,
  GridActionsCellItem,
  GridColumns,
  GridValueFormatterParams,
  GridRowParams,
  GridRenderCellParams,
} from "@mui/x-data-grid";

import { RunnersDialog } from "../RunnersDialog/RunnersDialog";
import { ConfigHelper } from "../../Config/ConfigHelper";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { Loading } from "../Feedback/Loading";

export const Events: React.FC<IEventsProps> = (props) => {
  const [openRunners, setOpenRunners] = React.useState(true);

  const [selectedEvent, setSelectedEvent] = React.useState<IEventKey>({
    clubEvent: "",
    dateId: 0,
  });
  const [events, setEvents] = React.useState<IEvent[]>();

  const [apiError, setApiError] = React.useState<string>("");

  React.useEffect(() => {
    const url = new ConfigHelper().GetEventsUrl(
      props.club,
      props.onlyFutureEvents
    );

    const fetchData = async () => {
      const response = await axios.get<IEvent[]>(url);

      if (response.status === 200) {
        setEvents(response.data);
      } else {
        setApiError(response.statusText);
      }
    };

    fetchData();
  }, [props.onlyFutureEvents, props.club]);

  const handleClickOpen = (eventKey: IEventKey) => {
    setOpenRunners(true);
    setSelectedEvent(eventKey);
  };

  const handleCloseOfViewRunners = (value?: IEvent) => {
    setOpenRunners(false);
    if (value) {
      if (events !== undefined) {
        for (let e of events) {
          if (e.dateId === value.dateId && e.clubEvent === value.clubEvent) {
            e.confirmedRunnersCount = value.confirmedRunnersCount;
            e.interestedRunnersCount = value.interestedRunnersCount;
            e.withdrawnRunnersCount = value.withdrawnRunnersCount;

            e.confirmedRunnersDetailed = value.confirmedRunnersDetailed;
            e.interestedRunnersDetailed = value.interestedRunnersDetailed;
            e.withdrawnRunnersDetailed = value.withdrawnRunnersDetailed;
          }
        }
      }
      setEvents(events);
    }
  };

  const viewEventByKey = React.useCallback(
    (eventkey: IEventKey) => () => {
      handleClickOpen(eventkey);
    },
    []
  );

  const viewEvent = React.useCallback(
    (event: GridRowParams<IEvent>) => () => {
      handleClickOpen({
        clubEvent: event.row.clubEvent,
        dateId: event.row.dateId,
      });
    },
    []
  );

  const widthForNumbers = 120;

  const renderParticipantsButton = React.useCallback(
    (params: GridRenderCellParams<any, IEvent, any>) => {
      if (params.row) {
        const eventKey: IEventKey = {
          clubEvent: params.row.clubEvent,
          dateId: params.row.dateId,
        };

        return (
          <strong>
            <Button variant="text" onClick={viewEventByKey(eventKey)}>
              {params && params.value !== undefined
                ? params.value.toString()
                : "0"}
            </Button>
          </strong>
        );
      }

      return <Button variant="text">0</Button>;
    },
    [viewEventByKey]
  );

  const renderEventLink = (params: GridRenderCellParams<any, IEvent, any>) => {
    if (params.row) {
      const eventKey: IEventKey = {
        clubEvent: params.row.clubEvent,
        dateId: params.row.dateId,
      };

      const eventUrl = `/event/${encodeURIComponent(eventKey.clubEvent)}/${
        eventKey.dateId
      }`;

      return <Link to={eventUrl}>{params.row.event}</Link>;
    }

    return <>Nameless</>;
  };

  const widthForDate = 180;
  const widthForMobileNumbers = 80;

  const browserColumns = React.useMemo<GridColumns<IEvent>>(
    () => [
      { field: "year", headerName: "Year", width: widthForMobileNumbers },
      { field: "prettyDateId", headerName: "Date", width: widthForDate },
      { field: "club", headerName: "Club", width: 75 },
      { field: "competitionGroup", headerName: "Comp", width: 75 },
      {
        field: "event",
        headerName: "Event",
        width: 200,
        renderCell: renderEventLink,
      },
      { field: "location", headerName: "Location", width: 200 },
      {
        field: "distanceKm",
        headerName: "Dist (Km)",
        width: widthForNumbers,
        align: "right",
      },
      {
        field: "distanceMiles",
        headerName: "Dist (Miles)",
        width: widthForNumbers,
        align: "right",
        valueFormatter: (params: GridValueFormatterParams<number>) => {
          if (params.value == null) {
            return "";
          }

          const valueFormatted = Math.floor(params.value).toLocaleString();
          return `${valueFormatted}`;
        },
      },
      {
        field: "distanctFromHaywardsHeathKm",
        headerName: "Dist From HH (KM)",
        width: widthForNumbers + 20,
        align: "right",
      },
      {
        field: "racersCount",
        headerName: "Raced",
        width: widthForMobileNumbers,
        align: "right",
        renderCell: renderParticipantsButton,
      },
      {
        field: "confirmedRunnersCount",
        headerName: "Confirmed",
        width: widthForNumbers,
        align: "right",
        disableClickEventBubbling: true,
        renderCell: renderParticipantsButton,
      },
      {
        field: "interestedRunnersCount",
        headerName: "Interested",
        width: widthForNumbers,
        align: "right",
        disableClickEventBubbling: true,
        renderCell: renderParticipantsButton,
      },
      {
        field: "withdrawnRunnersCount",
        headerName: "Withdrawn",
        width: widthForNumbers,
        align: "right",
        disableClickEventBubbling: true,
        renderCell: renderParticipantsButton,
      },
      {
        field: "actions",
        type: "actions",
        headerName: "View Runners",
        width: 160,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<DirectionsRunIcon />}
            label="Runners"
            onClick={viewEvent(params)}
          />,
        ],
      },
    ],
    [viewEvent, renderParticipantsButton]
  );

  const mobileColumns = React.useMemo<GridColumns<IEvent>>(
    () => [
      { field: "date", headerName: "Date", width: widthForDate },
      {
        field: "event",
        headerName: "Event",
        width: 180,
        disableClickEventBubbling: true,
        renderCell: renderEventLink,
      },
      {
        field: "actions",
        type: "actions",
        headerName: "View Runners",
        width: 160,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<DirectionsRunIcon />}
            label="Runners"
            onClick={viewEvent(params)}
          />,
        ],
      },
      {
        field: "RacersCount",
        headerName: "Raced",
        width: widthForMobileNumbers,
        align: "right",
        disableClickEventBubbling: true,
        renderCell: renderParticipantsButton,
      },
      {
        field: "confirmedRunnersCount",
        headerName: "Confirmed",
        width: widthForMobileNumbers,
        align: "right",
        disableClickEventBubbling: true,
        renderCell: renderParticipantsButton,
      },
      {
        field: "interestedRunnersCount",
        headerName: "Interested",
        width: widthForMobileNumbers,
        align: "right",
        disableClickEventBubbling: true,
        renderCell: renderParticipantsButton,
      },
      {
        field: "distanceKm",
        headerName: "Dist (Km)",
        width: widthForNumbers,
        align: "right",
      },
      { field: "competitionGroup", headerName: "Comp", width: 75 },
      { field: "location", headerName: "Location", width: 200 },
    ],
    [viewEvent, renderParticipantsButton]
  );

  if (events) {
    const rows: GridRowsProp<IEvent> = events;

    if (isMobile) {
      return (
        <div>
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              getRowId={(row) => `${row.date}-${row.event}`}
              rows={rows}
              columns={mobileColumns}
            />
          </div>
          <RunnersDialog
            eventKey={selectedEvent}
            open={openRunners}
            onClose={handleCloseOfViewRunners}
            showInDialog={true}
          />
        </div>
      );
    }

    return (
      <div>
        <div style={{ height: 1000, width: "100%" }}>
          <DataGrid
            getRowId={(row) => `${row.date}-${row.event}`}
            rows={rows}
            columns={browserColumns}
          />
        </div>
        <RunnersDialog
          eventKey={selectedEvent}
          open={openRunners}
          onClose={handleCloseOfViewRunners}
          showInDialog={true}
        />
      </div>
    );
  }

  if (apiError.length > 1) {
    return <div>Error Occured - {apiError}</div>;
  }

  return <Loading loadingMessage={`Loading Events for ${props.club}`} />;
};
