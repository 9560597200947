import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";

import { PageLoader } from "../AuthZero/page-loader";

export interface ILoadingProps {
  loadingMessage: string;
}

export const Loading: React.FC<ILoadingProps> = (props) => {
  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        {props.loadingMessage}
      </Typography>
      <CircularProgress />
      <PageLoader message="Loading..." />
    </Box>
  );
};
